/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';

// material-ui
import {
    Grid,
    Tabs,
    Tab,
    Autocomplete,
    TextField,
    InputAdornment,
    Link as Linkk,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListSubheader
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import moment from 'moment';
import useLeagues from 'hooks/useLeagues';
import Pools from './Pools';
import { Link } from 'react-router-dom';
// import Matches from './Matches';
import MatchInputDrawer from 'views/admin/AdminHub/TournamentManager/MatchInputDrawer';
import SearchIcon from '@mui/icons-material/Search';
import GeneratePoolsDrawer from './GeneratePoolsDrawer';
import GenerateBracketDrawer from './GenerateBracketDrawer';
import Matches from 'views/admin/AdminHub/TournamentManager/Matches';
import MedalRound from 'views/admin/AdminHub/TournamentManager/MedalRound';
import capitalizeWords from 'utils/capitalizeWords';

// ==============================|| SOCIAL PROFILE - FOLLOWERS ||============================== //
const LeagueMatches = ({ leagueId, isAdmin, type }) => {
    const currentDate = moment.utc();
    const { eventPools, setEventPools, getLeagueEventList, getLeagueGroups, getEventPools, eventBracket, setValue } = useLeagues();

    const [selectedEvent, setSelectedEvent] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedMatchId, setSelectedMatchId] = useState();
    const [matchSearch, setMatchSearch] = useState('');
    const handleDrawerOpen = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMatchSearch = (matchId) => {
        setSelectedMatchId(matchId);
        handleDrawerOpen();
    };

    const [eventDropdownOptions, setEventDropdownOptions] = useState([]);
    const [tab, setTab] = useState(0);
    const [selectedPool, setSelectedPool] = useState();
    const [selectedRound, setSelectedRound] = useState();

    useEffect(() => {
        // If type is 'league', don't group the events
        const lastIndex = eventDropdownOptions.length - 1;
        if (type === 'league') {
            const latestWeek = eventDropdownOptions.filter((item) => item.status === 'in_progress');
            if (latestWeek.length > 0) {
                setSelectedEvent(latestWeek[0]);
            } else if (eventDropdownOptions[lastIndex] && moment().isAfter(moment(eventDropdownOptions[lastIndex].start_date_time))) {
                setSelectedEvent(eventDropdownOptions[lastIndex]);
            } else {
                setSelectedEvent(eventDropdownOptions[0]);
            }
        } else {
            // For 'tournament', sort and select the first event
            setSelectedEvent(eventDropdownOptions.sort((a, b) => -b.group.localeCompare(a.group))[0]);
        }
    }, [eventDropdownOptions, type]);

    useEffect(() => {
        if (leagueId && type) {
            getLeagueEventList(leagueId, type).then((freshEventList) => {
                setEventDropdownOptions(freshEventList || []);
            });
        }
    }, [leagueId, type]);

    useEffect(() => {
        if (selectedEvent?.id) {
            if (type === 'league') {
                getLeagueGroups(selectedEvent?.id).then((res) => {
                    if (res?.length > 0) {
                        setEventPools(res);
                        setSelectedPool(res[0]);
                    } else {
                        setEventPools([]);
                        setSelectedPool('');
                    }
                });
            } else if (type === 'tournament') {
                getEventPools(selectedEvent?.id).then((res) => {
                    setValue('eventbracket', res.bracket);
                    if (res.data?.length > 0) {
                        setEventPools(res.data);

                        setSelectedPool(res.data[0]);
                    } else {
                        setEventPools([]);
                        setSelectedPool('');
                    }
                    if (res.bracket) {
                        setSelectedPool(res.bracket);
                    }
                });
            }
        }
    }, [selectedEvent]);

    const [generateDrawerOpen, setGenerateDrawerOpen] = useState(false);
    const [generateBracketOpen, setGenerateBracketOpen] = useState(false);
    const [eventId, setEventId] = useState();
    const [teamLength, setTeamLength] = useState();
    const handleGenerateDrawer = (selectedEventId, selectedTeamLength) => {
        setEventId(selectedEventId);
        setTeamLength(selectedTeamLength);
        setGenerateDrawerOpen(!generateDrawerOpen);
    };
    const handleGenerateBracketDrawer = () => {
        setGenerateBracketOpen(!generateBracketOpen);
    };
    const poolAction = () => {
        setTab(1);
    };

    const renderCurrentTab = () => {
        switch (tab) {
            case 'overview':
                return (
                    <div>
                        <p> overview tab</p>
                    </div>
                );
            case 0:
                return (
                    <Pools // shows tournament matches too
                        poolAction={poolAction}
                        league
                        eventPools={eventPools}
                        setTab={setTab}
                        setSelectedPool={setSelectedPool}
                        eventId={selectedEvent?.id}
                        eventName={selectedEvent?.title}
                        handleGenerateDrawer={handleGenerateDrawer}
                    />
                );
            case 1:
                return (
                    <Matches
                        eventPools={eventPools}
                        selectedPool={selectedPool}
                        setSelectedPool={setSelectedPool}
                        setTab={setTab}
                        handleDrawerOpen={handleDrawerOpen}
                        setSelectedMatchId={setSelectedMatchId}
                        selectedEvent={selectedEvent}
                        selectedRound={selectedRound}
                        setSelectedRound={setSelectedRound}
                        eventBracket={eventBracket}
                        eventId={selectedEvent?.id}
                        isAdmin={isAdmin}
                        type={type}
                    />
                );
            case 2:
                return (
                    <MedalRound
                        isAdmin={false}
                        eventId={selectedEvent?.id}
                        handleGenerateBracketDrawer={handleGenerateBracketDrawer}
                        selectedEvent={selectedEvent}
                    />
                );

            default:
                return (
                    <div>
                        <p> overview tab</p>
                    </div>
                );
        }
    };

    const handleMainTabChange = (event, newValue) => {
        setTab(newValue);
        // If we're leaving the Tournament Manager tab, reset the subtab
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const tabOptions = type === 'league' ? ['Groups', 'Matches'] : ['Pools', 'Matches', 'Bracket'];

    function getDefaultOption(options) {
        if (!options) {
            return options;
        }
        const latestWeek = options.filter((item) => item.status === 'in_progress');
        if (latestWeek.length > 0) {
            return latestWeek[0];
        }
        return options && options[0];
    }

    function renderTabs() {
        return (
            <Tabs
                value={tab}
                onChange={handleMainTabChange}
                variant="scrollable"
                sx={{
                    // marginTop: 2.5,
                    '& .MuiTabs-flexContainer': {
                        border: 'none'
                    },
                    '& a': {
                        minHeight: 'auto',
                        minWidth: 10,
                        py: 1.5,
                        px: 1,
                        mr: 2.25,
                        color: 'grey.700',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    '& a.Mui-selected': {
                        color: 'primary.main'
                    },
                    '& a > svg': {
                        marginBottom: '4px !important',
                        mr: 1.25
                    }
                }}
            >
                {tabOptions.map((option, index) => (
                    <Tab
                        key={index}
                        component={Link}
                        // to={option.to}
                        // icon={option.icon}
                        label={option}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
        );
    }

    function renderAdmin(matchSearch) {
        return (
            <Grid>
                <TextField
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Link
                                    component="button"
                                    value={matchSearch}
                                    onClick={() => {
                                        handleMatchSearch(matchSearch);
                                    }}
                                >
                                    <SearchIcon />
                                </Link>
                            </InputAdornment>
                        )
                    }}
                    onChange={(event) => setMatchSearch(event.target.value)}
                    id="match_search"
                    placeholder="Match ID Search"
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            handleMatchSearch(matchSearch);
                        }
                    }}
                />
            </Grid>
        );
    }
    let menuItems;
    if (type === 'league') {
        // For leagues, list events without grouping
        menuItems = eventDropdownOptions.map((item) => (
            <MenuItem key={item.id} value={item}>
                {item.title}
            </MenuItem>
        ));
    } else {
        // For tournaments, group events
        const uniqueGroups = Array.from(new Set(eventDropdownOptions.map((option) => option.group)));
        const groupedOptions = uniqueGroups.map((group) => ({
            group,
            items: eventDropdownOptions.filter((option) => option.group === group)
        }));
        menuItems = groupedOptions.flatMap(({ group, items }) => [
            <ListSubheader sx={{ color: 'primary.dark' }} key={group}>
                {capitalizeWords(group)}
            </ListSubheader>,
            ...items.map((item) => (
                <MenuItem key={item.id} value={item}>
                    {item.title}
                </MenuItem>
            ))
        ]);
    }

    function renderEventDropdown() {
        return (
            <Grid item mb={1} xs={12} mt={2} sx={{ paddingRight: 4 }}>
                {eventDropdownOptions.length > 1 && (
                    <Autocomplete
                        data-id="LeagueMatches-index-LeagueMatches-renderEventDropdown"
                        disableClearable
                        options={eventDropdownOptions}
                        onChange={(event, value) => setSelectedEvent(value)}
                        defaultValue={getDefaultOption(eventDropdownOptions)}
                        getOptionLabel={(option) => (option ? option.title : '')}
                        renderInput={(params) => <TextField {...params} label={type === 'league' ? 'League Events' : 'Events'} />}
                    />
                )}
            </Grid>
        );
    }

    return (
        <Grid
            container
            data-test-id="views/groups//leagues/LeagueMatches/index"
            spacing={gridSpacing}
            data-id="views-groups-leagues-LeagueMatches-index-LeagueMatches"
        >
            <Grid item xs={12}>
                <Grid container justifyContent="flex-start" xs={12} mt={2}>
                    <Grid container xs={12} justifyContent="space-between" alignSelf="flex-end">
                        {renderTabs()}
                        {isAdmin && renderAdmin(matchSearch)}
                        {renderEventDropdown()}
                    </Grid>
                </Grid>
            </Grid>
            {renderCurrentTab()}
            <MatchInputDrawer
                handleDrawerOpen={handleDrawerOpen}
                openSidebar={drawerOpen}
                selectedMatchId={selectedMatchId}
                eventId={leagueId}
                divisionId={selectedEvent?.id}
                type="league"
            />
            <GeneratePoolsDrawer
                handleGenerateDrawer={handleGenerateDrawer}
                generateDrawerOpen={generateDrawerOpen}
                eventId={eventId}
                teamLength={teamLength}
            />
            <GenerateBracketDrawer
                handleGenerateDrawer={handleGenerateBracketDrawer}
                generateDrawerOpen={generateBracketOpen}
                selectedEvent={selectedEvent}
                type={type}
            />
        </Grid>
    );
};

export default LeagueMatches;
