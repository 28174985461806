/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

// material-ui
import { Grid, Stack, Chip, Button, MenuItem, Menu, Checkbox, FormControlLabel } from '@mui/material';

// assets
import AnimateButton from 'ui-component/extended/AnimateButton';

// project imports
import useLeagues from 'hooks/useLeagues';

const ActionsChip = ({ team, onClickSubButton }) => {
    const { deleteUserFromPlayingGroup, putUserPlayingGroup, setEventPools, eventPools } = useLeagues();
    // const { getEventPools, eventPools, setEventPools, eventBracket, setValue } = useLeagues();
    const [isSub, setIsSub] = useState(team?.is_sub === 1);
    const [isWithdrawn, setIsWithdrawn] = useState(team?.absent === 1);

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const handleIsSubCheckboxChange = (event) => {
        const newValue = event.target.checked ? 1 : 0;
        putUserPlayingGroup(team?.id, { is_sub: newValue }).then((res) => console.log('res', res));
        setIsSub(event.target.checked);

        setEventPools((prevEventPools) =>
            prevEventPools.map((eventPool) => ({
                ...eventPool,
                pool_teams: eventPool.pool_teams.map((prevTeam) => (prevTeam.id === team?.id ? { ...team, is_sub: newValue } : prevTeam))
            }))
        );
    };

    const handleIsWithdrawnCheckboxChange = (event) => {
        const newValue = event.target.checked ? 1 : 0;
        putUserPlayingGroup(team?.id, { absent: newValue }).then((res) => console.log('res', res));
        setIsWithdrawn(event.target.checked);

        setEventPools((prevEventPools) =>
            prevEventPools.map((eventPool) => ({
                ...eventPool,
                pool_teams: eventPool.pool_teams.map((prevTeam) => (prevTeam.id === team?.id ? { ...team, absent: newValue } : prevTeam))
            }))
        );
    };

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => setMenuAnchorEl(null);

    function handleSubButtonClick() {
        handleClose();
        onClickSubButton(team?.user);
    }

    function handleDeleteButtonClick() {
        handleClose();
        deleteUserFromPlayingGroup(team?.id).then((res) => console.log(res));
        console.log(eventPools);
        const teamId = team?.id;
        setEventPools((prevEventPools) =>
            prevEventPools.map((eventPool) => ({
                ...eventPool,
                pool_teams: eventPool.pool_teams.filter((prevTeam) => prevTeam.id !== teamId)
            }))
        );
    }

    return (
        <>
            <Chip
                size="medium"
                label="Actions"
                onClick={(e) => handleMenuClick(e)}
                sx={{
                    mt: 1,
                    // pl: 1.5,
                    // width: 40,
                    // height: 40,
                    borderRadius: '10%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                    // color: 'white'
                }}
            />
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                keepMounted
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem>
                    <FormControlLabel control={<Checkbox onChange={handleIsSubCheckboxChange} />} label="Is A Sub" checked={isSub} />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={<Checkbox onChange={handleIsWithdrawnCheckboxChange} />}
                        label="Is Withdrawn"
                        checked={isWithdrawn}
                    />
                </MenuItem>
                <MenuItem>
                    <AnimateButton>
                        <Button type="submit" variant="contained" color="warning" onClick={() => handleSubButtonClick()}>
                            Sub
                        </Button>
                    </AnimateButton>
                </MenuItem>
                <MenuItem>
                    <AnimateButton>
                        <Button type="submit" variant="contained" color="error" onClick={() => handleDeleteButtonClick()}>
                            Delete
                        </Button>
                    </AnimateButton>
                </MenuItem>
            </Menu>
        </>
    );
};

const EditPlayerPool = ({ team, onClickSubButton }) => (
    <Grid item xs={12}>
        <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
            <ActionsChip onClickSubButton={onClickSubButton} team={team} />
        </Stack>
    </Grid>
);
export default EditPlayerPool;
