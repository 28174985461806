function calculateDiff(pointsEarned, pointsAgainst) {
    return (pointsEarned - pointsAgainst) / pointsEarned;
}

function calculateDisproportionalityScore(PD, wins, losses, k = 5) {
    const netWins = wins - losses;
    const expectedPD = k * netWins; // Adjusted expected point differential

    const disproportion = PD - expectedPD;
    // const winDisproportion = 7 - disproportion / 2 / wins;
    // const lossDisproportion = 7 + disproportion / 2 / losses;
    // console.log(`Average opponent's score when game won: ${winDisproportion.toFixed(2)}`);
    // console.log(`Average score when opponent won: ${lossDisproportion.toFixed(2)}`);

    return disproportion;
}

function sortByWinsDesc(a, b) {
    return b.wins - a.wins;
}

function sortByWinsAsc(a, b) {
    return a.wins - b.wins;
}

function sortByLossesDesc(a, b) {
    return b.losses - a.losses;
}

function sortByLossesAsc(a, b) {
    return a.losses - b.losses;
}

function sortByPointsEarnedDesc(a, b) {
    return b.points_earned - a.points_earned;
}

function sortByPointsEarnedAsc(a, b) {
    return a.points_earned - b.points_earned;
}

function sortByPointsAllowedDesc(a, b) {
    return b.points_against - a.points_against;
}

function sortByPointsAllowedAsc(a, b) {
    return a.points_against - b.points_against;
}

function sortByPointDifferentialDesc(a, b) {
    return b.point_differential - a.point_differential;
}

function sortByPointDifferentialAsc(a, b) {
    return a.point_differential - b.point_differential;
}

function sortByPointDifferentialPercentageDesc(a, b) {
    return calculateDiff(b.points_earned, b.points_against) - calculateDiff(a.points_earned, a.points_against);
}

function sortByPointDifferentialPercentageAsc(a, b) {
    return calculateDiff(a.points_earned, a.points_against) - calculateDiff(b.points_earned, b.points_against);
}

function sortByNameDesc(a, b) {
    return a.name.localeCompare(b.name);
}

function sortByNameAsc(a, b) {
    return b.name.localeCompare(a.name);
}

function calculateDifferential(pointsEarned, pointsAgainst) {
    return 100 * ((pointsEarned - pointsAgainst) / pointsEarned);
}

function sortByDisproportionAsc(a, b) {
    return (
        calculateDisproportionalityScore(a.points_earned - a.points_against, a.wins, a.losses) -
        calculateDisproportionalityScore(b.points_earned - b.points_against, b.wins, b.losses)
    );
}

function sortByDisproportionDesc(a, b) {
    return (
        calculateDisproportionalityScore(b.points_earned - b.points_against, b.wins, b.losses) -
        calculateDisproportionalityScore(a.points_earned - a.points_against, a.wins, a.losses)
    );
}

export {
    calculateDifferential,
    calculateDisproportionalityScore,
    sortByLossesAsc,
    sortByLossesDesc,
    sortByNameAsc,
    sortByNameDesc,
    sortByPointDifferentialAsc,
    sortByPointDifferentialDesc,
    sortByPointDifferentialPercentageAsc,
    sortByPointDifferentialPercentageDesc,
    sortByPointsAllowedAsc,
    sortByPointsAllowedDesc,
    sortByPointsEarnedAsc,
    sortByPointsEarnedDesc,
    sortByWinsAsc,
    sortByWinsDesc,
    sortByDisproportionAsc,
    sortByDisproportionDesc
};
