import React, { useEffect } from 'react';

// material-ui
import { Chip, Grid, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import useLeagues from 'hooks/useLeagues';
import LeagueStatsTable from './LeagueStatsTable';
import capitalizeWords from 'utils/capitalizeWords';

const LeagueStats = ({ leagueId, type = 'league' }) => {
    const { getLeagueStats } = useLeagues();

    useEffect(() => {
        getLeagueStats(leagueId);
    }, [leagueId]);

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Typography variant="h3">{capitalizeWords(type)} Stats</Typography>
                            <Grid ml={1}>
                                <Chip label="All Time" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <LeagueStatsTable />
        </MainCard>
    );
};

export default LeagueStats;
