/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    CardContent,
    Fab,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import Chip from 'ui-component/extended/Chip';
import { useDispatch } from 'store';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddTwoTone';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import useLeagues from 'hooks/useLeagues';
import getReadableDateTime from 'utils/get_readable_datetime';
import DeleteEventDialog from 'views/admin/Events/components/DeleteEventDialog';
import CurrencyFormat from 'react-currency-format';
import { capitalize } from 'lodash';
import { VisibilityOff } from '@mui/icons-material';
import actionSnackbar from 'ui-component/actionSnackbar';
import CardModal from 'ui-component/CardModal';
import FormComponents from 'ui-component/FormComponents';
import moment from 'moment';

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a.event, b.event, orderBy)
        : (a, b) => -descendingComparator(a.event, b.event, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

// table header options
const headCells = [
    {
        id: 'id',
        numeric: true,
        label: '',
        align: 'center'
    },
    {
        id: 'name',
        numeric: false,
        label: 'Product Name',
        align: 'left'
    },
    {
        id: 'type',
        numeric: false,
        label: 'Event Type',
        align: 'left'
    },
    {
        id: 'start_date',
        numeric: false,
        label: 'Start Date',
        align: 'left'
    },
    {
        id: 'entry_fee',
        numeric: true,
        label: 'Price',
        align: 'right'
    },
    {
        id: 'orders_sum_amount_transferred',
        numeric: true,
        label: 'Revenue',
        align: 'right'
    },
    {
        id: 'totalTeams',
        numeric: true,
        label: 'Teams',
        align: 'right'
    },

    {
        id: 'status',
        numeric: true,
        label: 'Status',
        align: 'center'
    }
];

// ==============================|| TABLE HEADER ||============================== //

function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, theme, selected }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead data-test-id="views/home/Home/EventList">
            <TableRow>
                {/* <TableCell padding="checkbox" sx={{ pl: 3 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell> */}
                {numSelected > 0 && (
                    <TableCell padding="none" colSpan={7}>
                        <EnhancedTableToolbar numSelected={selected?.length} />
                    </TableCell>
                )}
                {numSelected <= 0 &&
                    headCells?.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Typography component="span" sx={{ display: 'none' }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Typography>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                {numSelected <= 0 && (
                    <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900' }}
                        >
                            Action
                        </Typography>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    theme: PropTypes.object,
    selected: PropTypes.array,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 2,
            pr: 1,
            color: numSelected > 0 ? 'secondary.main' : 'inherit'
        }}
    >
        {numSelected > 0 ? (
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="h4" component="div">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                Nutrition
            </Typography>
        )}

        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ==============================|| PRODUCT LIST ||============================== //

const EventList = ({ handleToggleModal, showTitle = true, clubId = null }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('start_date');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialsRows] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [open, setOpen] = useState(false);
    // const { products } = useSelector((state) => state.product);
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [newStartDate, setNewStartDate] = useState(moment());
    const [duplicateEvent, setDuplicateEvent] = useState();
    const toggleDuplicateModal = () => {
        setDuplicateModalOpen(!duplicateModalOpen);
    };

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const { getEventList, deleteTournament, deleteLeague, eventList, duplicateLeague } = useLeagues();
    const [selectedEventId, setSelectedEventId] = React.useState(null);
    const [eventCount, setEventCount] = useState({ upcoming_count: null, previous_count: null });

    const handleMenuClick = (event, eventId) => {
        setMenuAnchorEl(event.currentTarget);
        setSelectedEventId(eventId);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
        setSelectedEventId(null);
    };
    const [isUpcoming, setIsUpcoming] = useState(true);
    const onSelected = (state) => {
        setIsUpcoming(state);
    };

    useEffect(() => {
        getEventList(isUpcoming, clubId).then((res) => {
            setEventCount({ upcoming_count: res.upcoming_count, previous_count: res.previous_count });
        });
    }, [isUpcoming]);

    useEffect(() => {
        setRows(eventList);
        setInitialsRows(eventList);
    }, [eventList]);

    const onDelete = (id, type) => {
        const deleteAction = type === 'tournament' ? deleteTournament : deleteLeague;
        deleteAction(id)
            .then(() => {
                actionSnackbar(true, 'Event deleted');
            })
            .catch(() => {
                actionSnackbar(true, 'Failed to delete event');
            });
    };

    // useEffect(() => {
    //     setRows(products);
    // }, [products]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        console.log('property', property);
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedId = rows?.map((n) => n.name);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected?.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected?.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };
    const getChipColor = (status) => {
        switch (status) {
            case 'published':
                return 'success';
            case 'draft':
                return 'warning';
            case 'cancelled':
                return 'error';
            default:
                return 'default';
        }
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

    return (
        <MainCard
            title="Hosted Events"
            content={false}
            border={false}
            elevation={16}
            boxShadow
            shadow={theme.shadows[6]}
            secondary={
                <Tooltip title="Add Event">
                    <Fab color="primary" size="small" sx={{ boxShadow: 'none', ml: 2, width: 32, height: 32, minHeight: 32 }}>
                        <AddIcon fontSize="small" onClick={handleToggleModal} />
                    </Fab>
                </Tooltip>
            }
        >
            <CardContent>
                <Grid container justifyContent="flex-start" alignItems="center" flexWrap="nowrap" spacing={0.5}>
                    <Button
                        variant={isUpcoming ? 'contained' : 'outlined'}
                        sx={{ borderRadius: 25, flexWrap: 'nowrap', fontSize: { xs: 10, sm: 14 } }}
                        onClick={() => onSelected(true)}
                    >
                        Current ({eventCount.upcoming_count})
                    </Button>
                    <Button
                        variant={!isUpcoming ? 'contained' : 'outlined'}
                        onClick={() => onSelected(false)}
                        sx={{ borderRadius: 25, marginLeft: 2, fontSize: { xs: 10, sm: 14 } }}
                    >
                        Previous ({eventCount.previous_count})
                    </Button>
                </Grid>
            </CardContent>

            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows?.length}
                        theme={theme}
                        selected={selected}
                    />
                    <TableBody>
                        {rows &&
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    if (typeof row === 'number') return null;
                                    const isItemSelected = isSelected(row.event?.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const mainImage = row.event?.item_images?.filter((item) => item.is_main === 1) || [];
                                    const coverImage = mainImage.length > 0 && mainImage[0]?.image_url;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            {/* <TableCell
                                                padding="checkbox"
                                                sx={{ pl: 3 }}
                                                onClick={(event) => handleClick(event, row.event?.name)}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            </TableCell> */}
                                            <TableCell
                                                align="center"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                onClick={(event) => handleClick(event, row.event?.name)}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <Avatar src={coverImage} size="md" variant="rounded" />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    component={Link}
                                                    to={`/clubs/events/${row.event.event_type}/${row.event.id}/${
                                                        row.role === 'admin' ? 'Dashboard' : 'Overview'
                                                    }`}
                                                    variant="subtitle1"
                                                    sx={{
                                                        color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900',
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    {row.event.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell> {capitalize(row.event.event_type)}</TableCell>
                                            <TableCell>{getReadableDateTime(row.event.start_date, 'date', false)}</TableCell>

                                            <TableCell align="right">
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    value={row.event.entry_fee || 0}
                                                    displayType="text"
                                                    thousandSeparator
                                                    prefix="$"
                                                />
                                            </TableCell>
                                            {row.role === 'admin' ? (
                                                <TableCell align="right">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        value={row.event.orders_sum_amount_transferred / 100}
                                                        displayType="text"
                                                        thousandSeparator
                                                        prefix="$"
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell align="center">
                                                    <VisibilityOff />
                                                </TableCell>
                                            )}
                                            <TableCell align="right">{row.event.totalTeams}</TableCell>
                                            <TableCell align="center">
                                                <Chip
                                                    size="small"
                                                    label={row.event.status}
                                                    chipcolor={getChipColor(row.event.status)}
                                                    sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                <IconButton onClick={(e) => handleMenuClick(e, row.event.id)} size="large">
                                                    <MoreHorizOutlinedIcon
                                                        fontSize="small"
                                                        aria-controls="menu-popular-card-1"
                                                        aria-haspopup="true"
                                                        sx={{ color: 'grey.500' }}
                                                    />
                                                </IconButton>
                                                <Menu
                                                    id="menu-popular-card-1"
                                                    anchorEl={menuAnchorEl}
                                                    open={Boolean(menuAnchorEl) && selectedEventId === row.event.id}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    variant="selectedMenu"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                    sx={{
                                                        '& .MuiMenu-paper': {
                                                            boxShadow: theme.customShadows.z1
                                                        }
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            navigate(`/clubs/events/${row.event.event_type}/${row.event.id}`);
                                                            handleClose();
                                                        }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setDuplicateEvent(row.event);
                                                            setDuplicateModalOpen(true);
                                                            handleClose();
                                                        }}
                                                    >
                                                        Duplicate
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedEvent(row.event);
                                                            setOpen(true);
                                                            handleClose();
                                                        }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DeleteEventDialog
                open={open}
                title="Are you sure you would like to delete this event?"
                description="Deleting this event is a permanent action and cannot be undone."
                handleClose={() => setOpen(!open)}
                onDelete={onDelete}
                selectedEvent={selectedEvent}
            />
            {duplicateModalOpen && (
                <CardModal
                    actionText="Duplicate"
                    open={duplicateModalOpen}
                    toggleModal={toggleDuplicateModal}
                    title="Select new starting date"
                    submitAction={() => {
                        duplicateLeague(duplicateEvent?.id, newStartDate).then(() => {
                            toggleDuplicateModal();
                            actionSnackbar(true, 'Event duplicated');
                        });
                    }}
                    content={
                        // eslint-disable-next-line no-nested-ternary
                        <Grid>
                            <FormComponents
                                input={{
                                    name: 'start_date',
                                    label: 'Starting Date',
                                    type: 'date',
                                    value: newStartDate,
                                    onChange: (e) => setNewStartDate(e),
                                    width: 12,
                                    smWidth: 6
                                }}
                            />
                        </Grid>
                    }
                    actionColor="primary"
                    showCancel
                />
            )}
        </MainCard>
    );
};

export default EventList;
